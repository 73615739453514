<template>
    <div id="bindcard">
        <rxNavBar v-if="androidFlg == false" title="绑定银行卡" androidOrIOSFlag="true"></rxNavBar>
        <rxNavBar v-else title="绑定银行卡"></rxNavBar>
        <!--  姓名  -->        
        <div class="cell-normal-addRadius addMarginTop">
            <span class="maker" :class="{'orangeColor':openingBankName.toString().trim().length>0}">•</span>
            <span class="cell-title">姓名</span>
            <span class="content-divide">|</span>
            <span><input  type="text" placeholder="输入开户姓名"  class="input-text"  v-model="openingBankName" @blur="checkName" /></span>
        </div>
        <!--  银行卡号  -->        
        <div class="cell-normal-addRadius" >
            <span class="maker" :class="{'orangeColor':bankCard.toString().trim().length>0}">•</span>
            <span class="cell-title">卡号</span>
            <span class="content-divide">|</span>
            <span>
                <input  type="text" style="width:195px" class="input-text font-bold" placeholder="输入开户卡号" maxlength="23"  oninput = "value=value.replace(/[^\d]/g,'')" @blur="bankCardKeyup"  v-model="bankCard" />
            </span>
        </div>
        <!--  开户银行  -->        
        <div class="cell-normal-addRadius" @click="showPicker = true">
            <span class="maker" :class="{'orangeColor': bankOfDeposit.dictionaryTitle}">•</span>
            <span class="cell-title">开户银行</span>
            <span class="content-divide">|</span>
            <span class="input-text">{{bankOfDeposit.dictionaryTitle}}</span>
        </div>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="bankList" @cancel="showPicker = false" :default-index="defaultIndex" value-key="dictionaryTitle" @confirm="onConfirm"/>
        </van-popup> 
        <!--  省份  -->        
        <div class="cell-normal-addRadius" @click="showOrHiddenOfProvince = true">
            <span class="maker" :class="{'orangeColor': province.name}">•</span>
            <span class="cell-title">省份</span>
            <span class="content-divide">|</span>
            <span class="input-text">{{province.name}}</span>
        </div>
        <van-popup v-model="showOrHiddenOfProvince" position="bottom">
            <van-picker show-toolbar :columns="provinceArr" @cancel="showOrHiddenOfProvince = false" value-key="name" @confirm="selectProvince"/>
        </van-popup>   
        <!--  城市  -->        
        <div class="cell-normal-addRadius" @click="showOrHiddenOfCity = true">
            <span class="maker" :class="{'orangeColor': city.name}">•</span>
            <span class="cell-title">城市</span>
            <span class="content-divide">|</span>
            <span class="input-text">{{city.name}}</span>
        </div>
        <van-popup v-model="showOrHiddenOfCity" position="bottom">
            <van-picker show-toolbar :columns="cityArr" @cancel="showOrHiddenOfCity = false" value-key="name" @confirm="selectCity"/>
        </van-popup> 
        <!--  支行  -->        
        <div class="cell-normal-addRadius" @click="searchSubBank" v-if="!inputSubBnakFlag">
            <span class="maker" :class="{'orangeColor': subBranch.subBankName}">•</span>
            <span class="cell-title">支行</span>
            <span class="content-divide">|</span>
            <span class="input-text">{{subBranch.subBankName}}</span>
        </div>        
        <van-popup v-model="showOrHiddenOfSubBank" position="bottom">
            <van-picker show-toolbar :columns="subBankList" @cancel="showOrHiddenOfSubBank = false" value-key="subBankName" @confirm="selectSubBank"/>
        </van-popup>    
        <!--  行号  -->        
        <div class="cell-normal-addRadius" >
            <span class="maker" :class="{'orangeColor': salesDepartmentNo}">•</span>
            <span class="cell-title">行号</span>
            <span class="content-divide">|</span>
            <span><input  type="text"  class="input-text" :disabled="!inputSubBnakFlag"  v-model="salesDepartmentNo"  placeholder="填写行号"/></span>
        </div>   
        <van-button  class="save-bind-Button_Disable" :class="{'save-bind-Button_Enable': buttonEnable}" :disabled="!buttonEnable" v-on:click="bind" >提交</van-button>     
    </div>
</template>

<script>
    import {
        NavBar,
        Button,
        Picker,
        Popup, Toast
    } from 'vant';
    import {addStaffBank, queryBaseData, queryStaffSearch,queryCityAndProvince,querySubBank} from "../../../getData/getData";
    import {globaluserId, responseUtil,getStaffId,checkAndroid, checkIOS,} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus";
    import config from "@/view/contract/config";

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            rxNavBar
        },
        data(){
            return {
                androidFlg: '',
                user_id:'',
                openingBankName:'',
                //卡号
                bankCard:'',
                //开户银行
                bankOfDeposit:{},
                bankList: [],
                showPicker: false,
                defaultIndex: '',
                customTel:'',
                //支行
                subBank: '',
                //行号
                salesDepartmentNo: '',
                provinceArr:[],
                cityArr:[],
                province:{id:'',name:''},
                city:{id:'',name:''},
                showOrHiddenOfProvince:false,
                showOrHiddenOfCity:false,
                showOrHiddenOfSubBank:false,
                subBranch:{
                    subBankName:''
                },
                subBankList:[],    //支行列表
                inputSubBnakFlag:false
            }
        },
        created() {  
            this.initDicData();
            this.getProvinceAndCity();
        },     
        mounted() {
            this.checkPhoneorMobel()
        },          
        methods: {
            checkPhoneorMobel() {
            if(checkAndroid()) {
                this.androidFlg = true
            }
            else if(checkIOS()) {
                this.androidFlg = false
            }
            },
            searchSubBank(){
                this.$router.push({
                        name:'referrer',
                        query:{
                            type:'7',
                            titleName:'支行',
                            bankId:this.bankOfDeposit.id,
                            cityCode:this.city.code

                    }
                })
            },
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
            queryPersonDetails(){
                let that = this;
                let queryPersonDetailsData={}
                queryPersonDetailsData.staff_id= getStaffId()
                queryStaffSearch(queryPersonDetailsData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        let data = response.data.data.data[0]
                            that.openingBankName = data.staffName || ''
                            that.bankCard = data.payCardCode ||''
                            that.bankOfDeposit.dictionaryTitle = data.dopeningBank || ''
                            that.bankOfDeposit.id = data.openingBank || ''
                            //that.subBank = data.subBank || ''
                            that.salesDepartmentNo = data.salesDepartmentNo || ''
                            that.subBranch.subBankName = data.subBank || ''
                            that.province.id=data.province_id
                            that.city.id=data.city_id
                            that.interbankId=data.interBankId
                            that.changeProvince()
                            that.province.name=data.provinceName
                            that.city.name=data.cityName
                            that.city.code= data.cityCode
                            that.getSubBankList()
                        
                    })
                })

            },
            //初始化字典数据
            initDicData:function(){
                const that = this
                let initData = {}
                initData.dbName = ["bankList"]
                initData.fdName = []
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.bankList.push(response.data.data.bankList[0]) //= response.data.data.bankList
                        that.bankList.push(response.data.data.bankList[2]) 
                    })
                })
            },
            onConfirm(value,index){
                if(value){
                    this.bankOfDeposit = value
                    this.defaultIndex = index
                }
                this.showPicker = false
                this.getSubBankList()
                this.subBranch.subBankName=''
                this.salesDepartmentNo=''                

            },

            checkName() {
                var re = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
                if(this.openingBankName){
                    if(!re.test(this.openingBankName)){
                        responseUtil.alertMsg(this,'请输入正确的姓名')
                        this.openingBankName = '';
                    }
                }
            },

            bind(){
                let _this=this;
                let bindBankCardData={}

                bindBankCardData.id = getStaffId();
                bindBankCardData.bankCard=_this.bankCard;
                bindBankCardData.openingBank=_this.bankOfDeposit.id;
                bindBankCardData.name = _this.openingBankName;
                bindBankCardData.subBank = _this.subBranch.subBankName
                bindBankCardData.salesDepartmentNo = _this.salesDepartmentNo
                bindBankCardData.provinceId=this.province.id
                bindBankCardData.cityId=this.city.id
                bindBankCardData.interbankId=this.interbankId?this.interbankId:''
                console.log(bindBankCardData)
                addStaffBank(bindBankCardData).then(function (response) {
                    responseUtil.dealResponse(_this, response, () => {
                        console.log(response)
                        if(200==response.status){
                            responseUtil.alertMsg(_this,'绑定成功')
                            _this.$router.go(-1)
                        }else {
                            responseUtil.alertMsg(_this,'绑定失败')
                        }
                    })
                }).catch(function (err) {
                    console.log("操作失败")
                })
            },
            bankCardKeyup () {

                var re = /^[1-9]\d{9,29}$/
                if(this.bankCard){
                    if(!re.test(this.bankCard)){
                        responseUtil.alertMsg(this,'请输入正确的银行卡号')
                        this.bankCard = '';
                    }
                }
            },
            //修改省份
            changeProvince(){
                for(let i in this.provinceArr){
                    if(this.province.id==this.provinceArr[i].id){
                        this.cityArr=this.provinceArr[i].childrens
                        break
                    }  
                }
            },           
            //查询全国所有省，市
            getProvinceAndCity(){
                let that=this
                let initData={}
                queryCityAndProvince(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.provinceArr=response.data.data.province
                        that.queryPersonDetails();
                    })
                })
            },    
            selectProvince(item) {
                this.province = item;
                this.showOrHiddenOfProvince = false;
                this.changeProvince()
                this.city={id:'',name:''}
                this.subBranch.subBankName=''
                this.salesDepartmentNo=''                
            },
            selectCity(item) {
                this.city = item;
                this.showOrHiddenOfCity = false;
                this.getSubBankList()
                this.subBranch.subBankName=''
                this.salesDepartmentNo=''                
            }, 
            getSubBankList(){
                let that=this
                let param={}
                param.bankId=that.bankOfDeposit.id
                param.cityCode=that.city.code
                if(!param.bankId||!param.cityCode){
                    return
                }
                querySubBank(param).then(function (response) {
                    that.subBankList=response.data.data.data
                })              
            },
            selectSubBank(item){
                this.subBranch = item;
                this.showOrHiddenOfSubBank = false; 
                this.salesDepartmentNo=item.interBankNo    
                this.interbankId=item.interbankId          
            },
        },
        computed:
            {
                buttonEnable () {
                    return (this.openingBankName.toString().trim().length>0 && this.bankCard.toString().trim().length>0 && this.bankOfDeposit.dictionaryTitle && this.subBranch.subBankName.toString().trim().length>0 && this.salesDepartmentNo.toString().trim().length>0&&this.province.id&&this.city.id)
                }
            },
        beforeDestroy() {
            eventBus.$off('referrer')
        } ,        
        activated() {
            eventBus.$on('referrer', function(data){
                console.log(data)
                if(data.interbankId){
                    this.interbankId=data.interbankId
                    this.salesDepartmentNo=data.interBankNo
                    this.subBranch.subBankName=data.subBankName
                }

            }.bind(this));        
        },
        beforeRouteLeave:function(to, from, next){
            if (from && to.name != 'referrer')
            {//此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
                if (this.$vnode && this.$vnode.data.keepAlive)
                {
                    if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache)
                    {
                        if (this.$vnode.componentOptions)
                        {
                            var key = this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
                                : this.$vnode.key;
                            var cache = this.$vnode.parent.componentInstance.cache;
                            var keys  = this.$vnode.parent.componentInstance.keys;
                            if (cache[key])
                            {
                                if (keys.length) {
                                    var index = keys.indexOf(key);
                                    if (index > -1) {
                                        keys.splice(index, 1);
                                    }
                                }
                                delete cache[key];
                            }
                        }
                    }
                }
                this.$destroy();
            }
            next();
        },             
    }
</script>


<style lang="less" scoped>
#bindcard{
  @container_width:9rem;
  @cell_height:50px;

  .cell-normal-addRadius{
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    width:@container_width;
    height: @cell_height;
    margin: 13px auto;
  }
  .addMarginTop{
    margin-top: 25px;
  }
  .maker{
    height: @cell_height;
    line-height: @cell_height;
    color: #999999;
    float: left;
    padding-left: 12px;
    padding-right: 11px;
    font-size: 24px;
  }
  .cell-title{
    float: left;
    font-size: 15px;
    font-weight: bold;
    height: @cell_height;
    //margin-left: 13px;
    line-height: @cell_height;
    width:18%;
  }
  .content-divide{
    float: left;
    font-size: 15px;
    color: #efefef;
    margin:0 10px;
    height: @cell_height;
    line-height: @cell_height;
  }
  .input-text{
    //vertical-align:middle;
    float: left;
    display: inline;
    //color: #999999;
    font-family: ATTriumvirateCondensed;
    font-size: 15px;
    border: none;
  }
  .font-bold{
    font-weight: bold;
  }

  .save-bind-Button_Disable{
    background-color: rgba(184, 184, 184, 0.2);
    width: 9rem;
    height: 1.22rem;
    border-radius: 8px;
    margin-top: 180px;
    margin-left: 19px;
    //margin:30px 19px;
    font-size: 18px;
    color: white;
    line-height: 1.22rem;
    text-align: center;
  }
  .save-bind-Button_Enable{
    background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

  }
  .orangeColor{
    color: #ff3c00;
  }


  input::-webkit-input-placeholder{/*Webkit browsers*/
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /*  Mozilla Firefox 4-18使用伪类 */
  input:-moz-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /* Mozilla Firefox 19+ 使用伪元素  */
  input::-moz-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /* IE10使用伪类 */
  input:-ms-input-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
}
.no-content{
    width:9rem;
}
.nofind{
    float:right;
    color:#ff3c00;
     margin: 13px auto;
    font-size:12px;
}
</style>
